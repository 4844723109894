import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { CONSTANTS } from '@shared/constants';
import { DataVersion } from '@shared/interfaces/data-version.interface';
import { ENVIRONMENT } from 'environment';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { SoftwareVersion } from './software-version.interface';
import { Profile } from './profile.interface';
import { Company } from './company.interface';
import { SecurityService } from '../security/security.service';
import { AuthService } from '../auth/auth.service';
import { AuthSmlService } from '../auth-sml/auth-sml.service';
import { SmlProfile } from '../auth-sml/auth-sml.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public createSession$ = new Subject<void>();
  public destroySession$ = new Subject<void>();
  private _session: Profile;

  constructor(
    private _securityService: SecurityService,
    private _authService: AuthService,
    private _router: Router,
    private _authSmlService: AuthSmlService
  ) {}

  public get session(): Profile {
    return this._session;
  }

  public get session$(): Observable<Profile> {
    return this.hasSession() ? of(this.session) : this.createSession$.pipe(map(() => this.session));
  }

  public logout(): void {
    try {
      if (ENVIRONMENT.isMiuEnvironment) {
        this._securityService.revokeToken();
        this._authService.logout();
      } else {
        this._authSmlService.logout();
      }
    } finally {
      this._destroySession();
    }
  }

  public logoutAndRedirect(returnUrl?: string): Promise<boolean> {
    this.logout();
    const options: NavigationExtras = returnUrl ? { queryParams: { returnUrl }, queryParamsHandling: 'merge' } : {};
    return this._router.navigate([CONSTANTS.loginUrl], options);
  }

  public initSession(profile: Profile | SmlProfile): void {
    if (profile) {
      if (ENVIRONMENT.isMiuEnvironment) {
        this._session = profile as Profile;
        Sentry.setUser({ email: (profile as Profile).login });
      } else {
        // TODO refactor in future - for now just map SML Profile to MIU Profile
        this._session = {
          login: (profile as SmlProfile).username,
          userUuid: '',
          firstName: (profile as SmlProfile).userDetails.givenName,
          lastName: (profile as SmlProfile).userDetails.familyName,
          company: {
            name: '',
            uuid: '',
          },
          groups: [],
          roles: [],
          dataVersions: [],
          softwareVersions: [],
          permissionLevels: {},
        };
      }
      this.createSession$.next();
    } else {
      this.logoutAndRedirect();
    }
  }

  public acquireSession(): Promise<void> {
    return this._securityService.getProfile().then(
      (profile: Profile) => {
        this.initSession(profile);
      },
      (error) => {
        this.logoutAndRedirect();
      }
    );
  }

  public refreshSession(): Promise<Profile | SmlProfile> {
    return new Promise((resolve, reject) => {
      const authService = ENVIRONMENT.isMiuEnvironment ? this._authService : this._authSmlService;
      authService.refreshAccessToken().then(
        () => {
          this._securityService.getProfile().then(
            (profile: Profile | SmlProfile) => {
              this.initSession(profile);
              resolve(profile);
            },
            (error) => {
              this.logoutAndRedirect();
              reject(error);
            }
          );
        },
        (error) => {
          this.logoutAndRedirect();
          reject(error);
        }
      );
    });
  }

  public hasSession(): boolean {
    return this.session !== undefined;
  }

  public get loginName(): string {
    return this.hasSession() ? this.session.login : '';
  }

  public get firstName(): string {
    return this.hasSession() ? this.session.firstName : '';
  }

  public get lastName(): string {
    return this.hasSession() ? this.session.lastName : '';
  }

  public get emailAddress(): string {
    return this.hasSession() ? this.session.login : '';
  }

  public get company(): Company {
    return this.hasSession() ? this.session.company : ({} as Company);
  }

  public latestMiuDataVersion(dataVersions: DataVersion[]): string {
    return this.hasSession() ? this._returnLatestVersion(dataVersions) : '';
  }

  public get miuDataVersions(): DataVersion[] {
    return this.hasSession() ? this.session.dataVersions : [];
  }

  public get permissionLevels(): { [accessZone: string]: number } {
    return this.hasSession() ? this._lowercaseProfilePermissionLevels(this.session.permissionLevels) : {};
  }

  public get miuSoftwareVersions(): SoftwareVersion[] {
    return this.hasSession() ? this.session.softwareVersions : [];
  }

  public get latestMiuSoftwareVersion(): string {
    return this.hasSession() && this.session.softwareVersions ? this._returnLatestVersion(this.session.softwareVersions) : '';
  }

  public get userUuid(): string {
    return this.hasSession() ? this.session.userUuid : '';
  }

  private _lowercaseProfilePermissionLevels(permissionLevels: { [accessZone: string]: number }): {
    [accessZone: string]: number;
  } {
    const permissionLevelsLowerCase: { [accessZone: string]: number } = {};
    Object.keys(permissionLevels).forEach((key: string) => {
      permissionLevelsLowerCase[key.toLowerCase()] = permissionLevels[key];
    });

    return permissionLevelsLowerCase;
  }

  private _destroySession(): void {
    this._session = undefined;
    this.destroySession$.next();
  }

  private _returnLatestVersion(versions: DataVersion[]): string {
    const values = versions.map((item) => item.description).sort();
    return values[versions.length - 1];
  }
}

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DocsService, PermissionService, SessionService } from '@shared/services';
import { accountMenu } from 'app/account/account-menu.constant';
import { appDocsMenu } from 'app/app-docs-menu.constant';
import { appMenu } from 'app/app-menu.constant';
import { contractMenu } from 'app/contract/contract-menu.constant';
import { docsMenu } from 'app/docs/docs-menu.constant';
import { downloadMenu } from 'app/download/download-menu.constant';
import { Observable, Subject, of } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { cloneDeep } from 'lodash';

import { NavItem } from './nav-item.interface';
const download = 'download';
const contract = 'contract';
const account = 'account';
const docs = 'docs';
const Home = 'Home';
const isMatch = (featureName: string, route: string) => new RegExp(`^\/${featureName}/.*`).test(route);
const featuresWithSubMenus = [download, contract, account, docs];

const featureMenus = {
  account: accountMenu,
  contract: contractMenu,
  download: downloadMenu,
  docs: docsMenu,
};

@Injectable()
export class NavService implements OnDestroy {
  public subnav$: EventEmitter<{ menu: NavItem[]; breadcrumb: string }> = new EventEmitter<{
    menu: NavItem[];
    breadcrumb: string;
  }>();
  private _unsubscribe: Subject<any> = new Subject();
  private _breadcrumb = Home;
  private _subnav = [];
  private _history: string[] = [];

  constructor(
    private _router: Router,
    private _location: Location,
    private _sessionService: SessionService,
    private _permissionService: PermissionService,
    private _docsService: DocsService
  ) {
    this._router.events.pipe(takeUntil(this._unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._history.push(event.urlAfterRedirects);
        const route = event.urlAfterRedirects;
        const feature = featuresWithSubMenus.find((featureName) => isMatch(featureName, route));

        if (feature) {
          this._processMenu(feature, route);
        } else {
          this.resetSubnav();
          this.resetBreadcrumb();
        }
      }
    });
  }

  private _getSubstringAfterLastSlash(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  public handleBack(): void {
    const historyCopy = cloneDeep(this._history);

    let index = historyCopy.length - 1;

    const programUuid = this._getSubstringAfterLastSlash(historyCopy[index]);
    do {
      index--;

      if (!historyCopy[index].includes(programUuid) && !historyCopy[index].includes('new')) {
        break;
      }
    } while (index >= 0);

    if (index >= 0) {
      this._history.splice(index);
      this._router.navigateByUrl(historyCopy[index]);
    } else {
      this.goToHome();
    }
  }

  public removeCurrentURLFromHistory(): void {
    if (this._history[this._history.length - 1] === this._router.url) {
      this._history.pop();
    }
  }

  public goBack(): void {
    this._history.pop();
    if (this._history.length > 0) {
      this._location.back();
    } else {
      this.goToHome();
    }
  }

  public resetBreadcrumb(): void {
    this._breadcrumb = Home;
    this.subnav$.emit({ menu: this._subnav, breadcrumb: Home });
  }

  public resetSubnav(): void {
    this._subnav = [];
    this.subnav$.emit({ menu: [], breadcrumb: this._breadcrumb });
  }

  public goToUrlIfAvailable(url: string): Promise<boolean> {
    return this.whenSessionReady$(() => [...appMenu(this._permissionService), ...appDocsMenu(this._docsService)])
      .pipe(
        takeUntil(this._unsubscribe),
        mergeMap((menus) => {
          const state = url.split('/')[0];
          const existsAndReadable = menus.find((item) => item.state === state && item.canRead);
          return existsAndReadable ? this._router.navigateByUrl(url) : this.goToHome();
        })
      )
      .toPromise();
  }

  public goToFirstMenuItem(): Promise<boolean> {
    return this.whenSessionReady$(() => [...appMenu(this._permissionService), ...appDocsMenu(this._docsService)])
      .pipe(
        takeUntil(this._unsubscribe),
        mergeMap((menus) => {
          const menuItems: NavItem[] = menus.filter((item) => item.state && item.canRead);
          if (menuItems.length > 0) {
            return this._router.navigate([menuItems[0].state]);
          } else {
            return this._sessionService.logoutAndRedirect();
          }
        })
      )
      .toPromise();
  }

  public goToHome(): Promise<boolean> {
    return this._router.navigate(['home']);
  }

  public whenSessionReady$(menuFn: () => any[]): Observable<any[]> {
    // eslint-disable-next-line
    const self = this;
    return this._sessionService.hasSession() ? of(menuFn.bind(self)()) : this._sessionService.createSession$.pipe(map(menuFn.bind(self)));
  }

  public filterReadable(menu: Partial<NavItem[]>): NavItem[] {
    return menu.filter((item) => item.canRead);
  }

  public getOtherMenus(menus: NavItem[]): NavItem[] {
    return this.filterReadable(menus).filter((item: NavItem) => item.isOther);
  }

  public getYourMenus(menus: NavItem[]): NavItem[] {
    return this.filterReadable(menus).filter((item: NavItem) => !item.isOther);
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  private _isDocsFeature(feature: string): boolean {
    return feature === docs;
  }

  private _processMenu(feature: string, route: string): void {
    this.whenSessionReady$(() => featureMenus[feature](this._isDocsFeature(feature) ? this._docsService : this._permissionService))
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((menu) => this._initMenu(menu, route));
  }

  private _initMenu(menu: Partial<NavItem[]>, route: string): void {
    this._subnav = this.filterReadable(menu.map(this._createNavItems));
    this._breadcrumb = this._findBreadcrumb(this._subnav, this._breadcrumb, route);
    this.subnav$.emit({ menu: this._subnav, breadcrumb: this._breadcrumb });
  }

  private _createNavItems(i: Partial<NavItem>): NavItem {
    return Object.assign(i, {
      svgIcon: i.svgIcon || undefined,
      canRead: i.canRead === undefined ? true : i.canRead,
    }) as NavItem;
  }

  private _findBreadcrumb(subnav: NavItem[], oldBreadcrumb: string, route: string): string {
    const breadcrumb = subnav.find((i) => route.indexOf(i.state) > -1);
    return breadcrumb ? breadcrumb.label : oldBreadcrumb;
  }
}
